<template>
	<div>
		<header class="position-relative">
			<!-- jumbotron -->
			<div class="jumbotron text-center slider-background">
				<div class="container">
					<div class="jumbotron__content mx-auto">
						<div
							id="carouselExampleIndicators"
							class="carousel slide"
							data-bs-ride="carousel"
						>
							<div class="carousel-indicators">
								<button
									type="button"
									data-bs-target="#carouselExampleIndicators"
									:data-bs-slide-to="index"
									aria-current="true"
									:aria-label="'Slide' + (index + 1)"
									v-for="(slide, index) in slides"
									:key="slide.id"
									:id="index"
								></button>
							</div>
							<div class="carousel-inner">
								<div
									class="carousel-item"
									v-for="slide in slides"
									:key="slide.id"
									v-html="slide.description"
								></div>
							</div>
						</div>
					</div>
				</div>
			</div>

			<!--
				TODO: delete this svg
				<svg width="100%" height="120" class="svg-of-header">
					<ellipse cx="50%" cy="120" rx="55%" ry="120" fill="white"/>
				</svg>
			-->
			<!-- svg for ellipse (curve line) -->
			<svg
				class="svg-of-header"
				xmlns="http://www.w3.org/2000/svg"
				xmlns:xlink="http://www.w3.org/1999/xlink"
				x="0px"
				y="0px"
				viewBox="0 0 1920 143.7"
				xml:space="preserve"
			>
				<path
					class="st0"
					d="M-0.5,144.2c143-46.3,493.2-145.6,975-143.5c464.4,2,801.7,97.1,945,143.5"
					fill="#FFFFFF"
				/>
			</svg>
		</header>
		<loader
			v-if="loading"
			style="height: 50vh"
			class="d-flex justify-content-center align-content-center flex-column"
		></loader>
		<main v-else>
			<!-- who are we -->
			<section class="who-are-we mb-6">
				<div class="container text-center">
					<h1 class="heading-1">{{ $t("page.who-are-we") }}</h1>
					<div class="fs-5 about-us">
						<div v-html="aboutUs.find((e) => e.Key === 'about').Value"></div>
					</div>
					<div class="privileges row justify-content-center">
						<div
							class="col-md-2 col-sm-4 col-6"
							v-for="value in values.slice(0, 5)"
							:key="value.id"
						>
							<privileges-card :data="value"></privileges-card>
						</div>
					</div>
				</div>
			</section>
			<section class="mb-6">
				<div class="container text-center">
					<div class="brands">
						<h1 class="heading-1 mb-4">{{ $t("page.brands") }}</h1>
						<div class="row">
							<!-- <div class="col-4 m-auto mb-3 brands--card">
								<img src="/Images/SPARK LUBE.png" alt="" />
								<span class="d-block text-uppercase my-2"> sparklube </span>
							</div>
							<div class="col-4 m-auto mb-3 brands--card">
								<img src="/Images/Logo.png" alt="" />
								<span class="d-block text-uppercase my-2"> spikelube </span>
							</div>
							<div class="col-4 m-auto mb-3 brands--card">
								<img src="/Images/ASIA.png" alt="" />
								<span class="d-block text-uppercase my-2"> ASIA </span>
							</div> -->
							<div class="col-4 m-auto mb-3 brands--card">
								<img src="/Images/brand1.jpg" alt="" />
								<!-- <span class="d-block text-uppercase my-2"> Temoil </span> -->
							</div>
							<div class="col-4 m-auto mb-3 brands--card">
								<img src="/Images/brand2.jpg" alt="" />
								<!-- <span class="d-block text-uppercase my-2"> Magicplus </span> -->
							</div>
							<div class="col-4 m-auto mb-3 brands--card">
								<img src="/Images/brand3.jpg" alt="" />
								<!-- <span class="d-block text-uppercase my-2"> Rixos Oil </span> -->
							</div>
						</div>
					</div>
				</div>
			</section>

			<!-- latest products -->
			<section class="latest-products mb-6">
				<div class="container text-center">
					<h1 class="heading-1 mb-4">{{ $t("page.latest-products") }}</h1>

					<div class="row justify-content-center">
						<div
							class="col-lg-3 col-sm-6 col-12 mb-3"
							v-for="product in products.slice(0, 4)"
							:key="product.id"
						>
							<product-card :data="product"> </product-card>
						</div>
					</div>
					<div class="see-more mt-5">
						<span class="fw-bold text-capitalize">
							<router-link
								to="allproducts"
								class="see-more-btn button button--outline mx-auto px-5"
							>
								{{ $t("page.all-products") }}</router-link
							>
						</span>
					</div>
				</div>
			</section>
		</main>
	</div>
</template>

<script>
import { API_URL } from "../constants";
import { mapState } from "vuex";
import store from "../store";
import PrivilegesCard from "../components/privilegesCard";
import ProductCard from "../components/ProductCard";
import Loader from "../components/Loader.vue";

export default {
	data: () => ({
		API_URL,
		currentPage: 1,
		loading: true,
		backgroundIndex: 0,
	}),

	components: {
		PrivilegesCard,
		ProductCard,
		Loader,
	},
	computed: {
		...mapState({
			products: (state) => state.products.products,
			slides: (state) => state.sliderImages.images,
			values: (state) => state.values.values,
			aboutUs: (state) => state.aboutUs.aboutUs,
		}),
	},
	methods: {
		fetchProducts() {
			Promise.all([
				this.$store.dispatch("products/fetchAll"),
				this.$store.dispatch("sliderImages/fetchAll"),
				this.$store.dispatch("values/fetchAll"),
				this.$store.dispatch("aboutUs/fetchAll"),
			])
				.then()
				.finally(() => {
					this.loading = false;

					this.setCarousel();

					console.log(this.aboutUs.find((e) => e.Key === "about").Value);
				});
		},
		changeBackgoundSLider() {},
		setCarousel() {
			document.querySelector(
				".jumbotron"
			).style.cssText += `background-image:linear-gradient(110deg, rgba(204, 204, 204, 0.83), rgba(204, 204, 204, 0.83)), url("${this.slides[0].image}");`;
			document
				.querySelector(".carousel-indicators button")
				.classList.add("active");
			document.querySelector(".carousel-item").classList.add("active");
			document
				.querySelector(".carousel")
				.addEventListener("slid.bs.carousel", () => {
					this.backgroundIndex = document.querySelector(
						".carousel-indicators button.active"
					).id;
					document.querySelector(".jumbotron").style.cssText += `
								opacity: 0.5;
								transition: unset;
								background-image:linear-gradient(110deg, rgba(204, 204, 204, 0.83), rgba(204, 204, 204, 0.83)), url("${
									this.slides[this.backgroundIndex].image
								}");
							`;
					setTimeout(() => {
						document.querySelector(".jumbotron").style.cssText += `
									transition: 200ms;
									opacity: 1;`;
					}, 50);
				});
		},
	},
	mounted() {
		this.fetchProducts();
	},
	//  TODO: remove //(comment) from next lines
	//	async beforeRouteEnter(to, from, next) {
	// 	await store.dispatch('products/fetchAll');
	// 	next();
	// }
};
</script>

<style lang="scss" scoped>
@import "../scss/shared-vue.scss";

// $jumbotron-side-image: "/Images/header.jpg";
// $jumbotron-side-image-small: "/Images/header.jpg";

// @include jumbotron-side-style(
// 	$jumbotron-side-image,
// 	$jumbotron-side-image-small
// );

.jumbotron {
	background: #eee;
}
.slider-background {
	background-size: cover;
	background-repeat: no-repeat;
}
.mb-6 {
	margin-bottom: 7rem;
	@media (max-width: $sm-size) {
		margin-bottom: 4rem;
	}
}
.categories-browse {
	padding-bottom: 100px;
}
.brands--card {
	img {
		height: 5rem;
		max-width: 9rem;
		object-fit: contain;
		filter: grayscale(1);
		transition: 200ms;
		@media (max-width: $sm-size) {
			height: 3.5rem;
			max-width: 5rem;
		}
	}
	img:hover {
		filter: grayscale(0);
		transform: scale(1.1);
	}
	span {
		font-size: 1.2rem;
		@media (max-width: $sm-size) {
			font-size: 1rem;
		}
	}
}
.product-card {
	width: 16rem;
	height: 20rem;

	@media (min-width: $lg-size) {
		width: 15rem;
		height: 21rem;
	}

	@media (min-width: $xl-size) {
		width: 16rem;
		height: 22rem;
	}
}
.see-more-btn {
	padding: 0.8em 3em;
	border-radius: 2em;
}

.category-card {
	width: 16rem;
	height: 16rem;

	@media (min-width: $sm-size) {
		width: 13.5rem;
		height: 13.5rem;
	}

	@media (min-width: $md-size) {
		width: 15rem;
		height: 15rem;
	}

	@media (min-width: $lg-size) {
		width: 13rem;
		height: 13rem;
	}

	@media (min-width: $xl-size) {
		width: 15rem;
		height: 15rem;
	}
}

.discover-more-btn {
	padding: 0.4rem 1.6rem;
}

.latest-news {
	background-color: $color-light;

	border-bottom-left-radius: 40%;
	border-bottom-right-radius: 40%;

	padding-top: 100px;
}

.news-card {
	max-width: 17rem;
	height: 15rem;

	@media (min-width: $md-size) {
		width: 20rem;
		height: 15rem;
	}

	@media (min-width: $lg-size) {
		width: 17.5rem;
		height: 13rem;
	}

	@media (min-width: $xl-size) {
		width: 22rem;
		height: 15rem;
	}
}
/*		carousel	 */
.carousel-item {
	height: 120px;
	font-size: 3rem;
	line-height: 60px;
	color: black;
	font-family: PoppinsBoldSemi;
	text-transform: uppercase;
}
.carousel-indicators {
	margin: 0;
	bottom: -100px;
}
.carousel-indicators button {
	width: 15px;
	height: 15px;
	border-radius: 50%;
}
</style>
<style >
.about-us p {
	margin-bottom: 0.3rem;
}
</style>